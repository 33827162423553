@import "../../scss/variables.scss";
.agreement-main {
  .nav-link {
    background-color: $lightblue;
    border-radius: 0 !important;
    padding: 0.9375rem;
    text-align: left;
    font-size: 16px;
    font-weight: $fw-400;
    color: $grey2;
    border-bottom: 0.0625rem solid $grey5;
    border-style: dashed;
  }
  .page-wrap {
    max-width: 1200px; /* Adjust this value to your preference */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add some space around the content */
  }
  .nav-item {
    .active {
      background-color: $white !important;
      color: $primary !important;
      position: relative;
    }
  }
}
.contract-pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #1d1d1d;
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }
}
.hub-outer {
  position: absolute;
  top: 0;
  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.custom-sidebar {
  position: fixed;
  right: 0;
  width: 205px !important;
  z-index: 99;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0px 2px 12px #f2f2f2;
}

.right-pdficon {
  p {
    display: inline-block;
    font-size: 20px;
    font-weight: $fw-600;
    color: $black2;
    margin-left: 0.9375rem;
    margin-bottom: 0;
    border-bottom: 0.0625rem solid $black2;
    cursor: pointer;
    @media only screen and (max-width: 1366px) {
      font-size: 16px;
      margin-left: 0.3125rem;
    }
  }
  .swich-outer {
    display: inline-flex;
    margin-right: 1.6875rem;
    span {
      margin-right: 0.625rem;
      display: inline-block;
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
  }
}
.first-outer {
  h2 {
    text-align: left;
    font-size: 20px;
    font-weight: $fw-600;
    color: #1d1d1d;
    border-bottom: 0.0625rem solid $grey5;
    padding-bottom: 0.5rem;
    @media only screen and (max-width: 1366px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.125rem;
      margin-top: 0.625rem;
    }
  }
}
.common-search {
  margin: 0 auto;
  text-align: center;
}
.message-icon {
  position: relative;
  svg {
    position: fixed;
    right: 0;
    top: 15%;
    border-radius: 25rem 0rem 0rem 25rem;
    background: $white;
    box-shadow: 0.25rem 0.0625rem 1.875rem 1.25rem rgba(0, 0, 0, 0.06);
    width: 3.5625rem;
    height: 3.5625rem;
    padding: 0.6875rem;
    cursor: pointer;
    z-index: 99;
    @media only screen and (max-width: 1366px) {
      padding: 0.4375rem;
      width: 2.5625rem;
      height: 2.5625rem;
    }
  }
}
.offcanvas-backdrop {
  width: 80vw;
  background-color: transparent !important;
}
.offcanvas {
  fill: $white;
  filter: drop-shadow(0.25rem 0.0625rem 1.875rem rgba(0, 0, 0, 0.06));
  &.offcanvas-end {
    top: 5rem !important;

    border-left: transparent !important;
    transform: translateX(100%);
    width: 16% !important;
    @media only screen and (max-width: 1200px) {
      width: 50% !important;
    }
    @media only screen and (max-width: 768px) {
      width: 70% !important;
    }
  }
}
.custom-canvas {
  padding: 10px 15px;
  border-bottom: 1px solid #f2f2f2;
  .offcanvas-title {
    font-size: 0.875rem;
    font-weight: $fw-400;
    color: $black2;
    padding-left: 10px;
    // border-left: 0.125rem solid $grey5;
    margin-left: 16px;
  }
  .btn-close {
    position: absolute;
    left: 15px;
    color: $primary !important;
  }
  .offcanvas-body {
    padding: 0.625rem 0px;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    max-height: 800px;
    overflow-y: auto;
  }
  .header-title {
    border-bottom: 0.0625rem solid $grey5;
    justify-content: flex-start;
    font-size: 12px;

    svg {
      cursor: pointer;
    }
  }
}
.body-iiner {
  h4 {
    font-size: 0.875rem;
    font-weight: $fw-500;
    color: $grey3;
    margin-bottom: 0.625rem;
  }
  h5 {
    font-size: 0.875rem;
    font-weight: $fw-300;
    color: $grey2;
    line-height: normal;
    margin-bottom: 0.9375rem;
  }
  p {
    font-size: 0.75rem;
    font-weight: $fw-400;
    text-align: right;
    // color: $grey3;
    margin-bottom: 0.625rem;
  }
  hr {
    margin: 0rem 0rem 0.625rem !important;
    border-top: 0.0625rem solid $grey5;
    opacity: 1 !important;
  }
}
.right-pdficon {
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .form-switch {
    font-size: 16px;
    font-weight: $fw-400;
    color: $black2;
  }
}
.agreement-mains {
  .pdf-outer {
    height: 56vh !important;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.625rem;
    width: 100% !important;
  }
  .add-contract {
    position: absolute;
    right: 0;
    top: 0;
  }
  @media only screen and (max-width: 768px) {
    .contract-pdf {
      display: inherit;
    }
  }
}
.modal-dialog {
  .btn-primary {
    background-color: $primary !important;
    border: 0.0625rem solid $primary !important;
  }
}
.right-pdficon {
  &.pdf-outer {
    height: auto !important;
    border-top: 0.0625rem solid $grey5;
    margin-top: 1.25rem;
    overflow: unset;
    padding-top: 0.625rem;
  }
}
.tab-content {
  width: 100%;
  .element {
    table {
      width: 100% !important;
      tr {
        border: 0.0625rem solid $grey5;
        td {
          padding: 0.5rem 0.625rem;
        }
      }
    }
  }
}
.pdf-inner {
  margin: 0rem 1.25rem !important;
  b {
    font-size: 20px;
    font-weight: $fw-600;
    color: #1d1d1d;
    margin-bottom: 8px;
    display: inline-block;
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 24px;
  }
}

.cursoor {
  cursor: pointer;
}

.custom-row {
  display: flex;
  .custom-leftbar {
    width: 28%;
  }
  .pdf-rightouter {
    width: 70%;
    margin-left: 1.25rem;
  }
}
.custom-row-pdf {
  display: flex;
  .custom-leftbar {
    width: 28%;
  }
  .pdf-rightouter {
    width: 70%;
    margin-left: 1.25rem;
  }
}
.onclick-add {
  display: flex;
  .custom-leftbar {
    width: 20%;
  }
  .pdf-rightouter {
    width: 80%;
    margin-left: 1.25rem;
  }
}

.onclick-add-pdf {
  display: flex;
  .custom-leftbar {
    width: 35%;
  }
  .pdf-rightouter {
    width: 80%;
    margin-left: 1.25rem;
  }
}

.orange {
  color: #ff8f27;
}

.popover {
  position: relative;
  margin-left: 760px;
  border: 1px solid grey;
  display: flex !important;
  justify-content: space-around;

  padding: 3px 6px;
}
.custom-leftbar {
  p {
    font-size: 16px;
    font-weight: 400;
    color: #4f4f4f;
    max-width: 370px;
    word-wrap: break-word;
    @media screen and (max-width: 1366px) {
      font-size: 12px;
    }
  }
}
.popover {
  position: relative;
  margin-left: 0px !important;
  border: 1px solid grey;
  display: inline-flex !important;
  justify-content: space-around;
  width: 143px;
  padding: 10px 10px;
  margin-left: 20px !important;
  margin-bottom: 10px;
}
.pdf-inner p span {
  color: #4f4f4f;
}
.doted-border {
  background-color: #f9fdff;
  padding: 8px 15px;
  border-bottom: 1px dashed #ccc;
}
.delete-icon {
  margin-left: -4px;
}

.bgGreen {
  background-color: green;
  color: white !important;
}

.green_dot_select {
  height: 20px;
  width: 20px;
  background-color: blue;
  border: none;
  border: 2px solid grey;
}

.green_dot {
  height: 20px;
  width: 20px;
  background-color: blue;
  border: none;
  margin-top: 5px;
}

.orange_dot {
  height: 20px;
  width: 20px;
  background-color: yellow;
  border: none;
}

.orange_dot_select {
  height: 20px;
  width: 20px;
  background-color: yellow;
  border: 2px solid grey;
  margin-top: 5px;
}

.addCommentShow {
  width: 370px;
  position: sticky;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 24px 13px;
  /* border: 1px solid rgb(204, 204, 204); */
  border-radius: 10px;
  display: block;
  z-index: 10000;
  box-shadow: 0px 0px 10px #ccc;
  textarea {
    height: 80px;
    margin-bottom: 5px;
  }
  p {
    font-weight: 600;
    margin-bottom: 23px;
  }
}
.addCommentHide {
  display: none;
}

.between {
  justify-content: space-between !important;
  margin-bottom: 0.6rem;
}
.addSend {
  border-radius: 50%;
  background-color: #00aeef;
  border: none;
  height: 40px;
  width: 40px;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.bg-yellow {
  background-color: yellow;
  color: black;
}

.bg-blue {
  background-color: blue;
  color: white !important;
}
.custom-btn.custom-primary.backg.btn.btn-primary {
  background-color: transparent !important;
  color: #00aeef !important;
  border: transparent !important;
}

.clause {
  p {
    margin-bottom: 0;
    @media screen and (max-width: 1366px) {
      font-size: 12px;
    }
  }
}
.text-orange {
  color: orange;
}
.text_red {
  color: red;
}
.text-green {
  color: green;
}
.text-black {
  color: black;
}

.line_orange {
  background-color: orange;
  max-height: 100%;
  margin-right: 5px;
  width: 100%;
  max-width: 5px;
}
.line_green {
  background-color: green;
  max-height: 100%;
  margin-right: 5px;
  width: 100%;
  max-width: 5px;
}
.line_red {
  background-color: red;
  max-height: 100%;
  margin-right: 5px;
  width: 100%;
  max-width: 5px;
}
.commentBox {
  padding-top: 16px;
  .close {
    position: absolute;
    top: 3px;
    right: 0px;
  }
  div {
    textarea:focus-visible {
      outline: none;
    }
  }
}
.leftScroll {
  overflow-y: scroll;
  max-height: 60vh;
}

.show_container {
  width: 93%;
  // transition-delay: 3s;
  transition: 0.5s;
}
.hide_container {
  transition: 0.5s;
  width: 100%;
}
mark {
  background-color: yellow;
}

.defination {
  font-weight: bold;
  text-decoration: underline;
}
.defination:hover {
  transition: all 0.3s ease;
}
.customClause p {
  // max-width: 1010px;
  position: relative;
}
.custom-tooltip {
  //position: relative;
  cursor: pointer;
  background-color: #ebf5ff !important;
  padding: 0 2px 0 2px;
}
.custom-tooltip span:hover {
  padding: 0 2px 0 2px;
  background-color: rgb(77, 118, 133) !important;
  color: white !important;
  a {
    color: white;
  }
}
.custom-tooltip .tooltip-text {
  visibility: hidden;
  background-color: #333333d7;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  width: auto;
  left: 0;
  bottom: 25px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.interipted-outer .py-3 {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
.ql-align-jusify {
  text-align: justify;
}
.marker div:last-child {
  width: 100%;
}
