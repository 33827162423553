@import "../src/scss/variables.scss";


body{
  font-family: 'Inter', sans-serif;
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}
::-webkit-input-placeholder { /* Edge */
  font-size:1.125rem;
  color: $grey4;
  @media only screen and (max-width: 1366px) {

    font-size: 0.875rem;
  }
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-size:1.125rem;
  color: $grey4;
  @media only screen and (max-width: 1366px) {

    font-size: 0.875rem;
  }
}

::placeholder {
  font-size:1.125rem;
  color: $grey4;
  @media only screen and (max-width: 1366px) {

    font-size: 0.875rem;
  }
}
input:focus-visible {
  outline: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
}



// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
