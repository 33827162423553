@import "../../scss/variables.scss";

.custom-btn {
  min-height: 2.6875rem;

  font-size: 1rem;
  font-weight: $fw-400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;
  padding: 0rem 1.25rem;
  border: 0.0625rem solid transparent;
  @media only screen and (max-width: 1366px) {
    font-size: 12px;
    min-height: 2.125rem;
  }
}

.custom-primary {
  background-color: $primary;
  border: 0.0625rem solid transparent;

  color: $white;

  @media only screen and (max-width: 1366px) {
    min-height: 2.125rem;
    font-size: 12px;
  }
  &:hover {
    background-color: transparent !important;
    border: 0.0625rem solid $primary;
    color: $primary;
  }
}
.border-btn {
  margin-bottom: 0.5625rem;
  background-color: transparent;
  border: 0.0625rem solid $primary;
  color: $black2;
  &:hover {
    background-color: $primary;
    border: 0.0625rem solid $primary;
    color: $white;
  }
}
.textarea {
  font-size: 16px;
  @media screen and (max-width: 1366px) {
    font-size: 12px;
  }
}
