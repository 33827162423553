// .chatBtn {
//   right: 4rem;
//   position: fixed;
//   top: 49rem;
//   border: none;
//   //   height: 100px;
//   //   width: 100px;
//   border-radius: 50%;
//   background-color: transparent;
//   .fill {
//     color: aqua;
//   }
// }

// .dPdbIW {
//   width: 100% !important;
// }
// .glfuN {
//   width: 100%;
//   background: #00aeef !important;
// }
// .hQsUiY {
//   background: #00aeef !important;
// }

/* chatBot.scss */

// .chat-container {
//   // position: fixed;
//   // bottom: 4rem;
//   // right: 0;
//   // margin-right: 1rem;
//   background-color: #ffffff;
//   padding: 1rem;
//   border: 1px solid #e5e7eb;
//   border-radius: 10px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   width: 498px;
//   height: 634px;
//   display: flex;
//   flex-direction: column;
// }

// .messages-container {
//   flex: 1;
//   overflow-y: auto;
//   padding-right: 1rem;
// }

// .message {
//   display: flex;
//   gap: 1rem;
//   margin: 1rem 0;
// }

// .ai-message {
//   justify-content: flex-end;
// }

// .user-message {
//   justify-content: flex-start;
// }

// .message-text {
//   flex: 1;
//   padding: 0.5rem;
//   border-radius: 8px;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
// }

// .ai-sender {
//   color: #3498db;
// }

// .user-sender {
//   color: #2ecc71;
// }

// .input-container {
//   display: flex;
//   align-items: center;
//   padding-top: 1rem;
// }

// .message-input {
//   flex: 1;
//   height: 40px;
//   border: 1px solid #e5e7eb;
//   border-radius: 4px;
//   padding: 0.5rem;
//   margin-right: 0.5rem;
//   outline: none;
// }

// .send-button {
//   background-color: #3498db;
//   color: #ffffff;
//   border: none;
//   border-radius: 4px;
//   padding: 0.5rem 1rem;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
// }

// .send-button:hover {
//   background-color: #2980b9;
// }

#chat1 .form-outline .form-control ~ .form-notch div {
  pointer-events: none;
  border: 1px solid;
  border-color: #eee;
  box-sizing: border-box;
  background: transparent;
}

#chat1 .form-outline .form-control ~ .form-notch .form-notch-leading {
  left: 0;
  top: 0;
  height: 100%;
  border-right: none;
  border-radius: 0.65rem 0 0 0.65rem;
}

#chat1 .form-outline .form-control ~ .form-notch .form-notch-middle {
  flex: 0 0 auto;
  max-width: calc(100% - 1rem);
  height: 100%;
  border-right: none;
  border-left: none;
}

#chat1 .form-outline .form-control ~ .form-notch .form-notch-trailing {
  flex-grow: 1;
  height: 100%;
  border-left: none;
  border-radius: 0 0.65rem 0.65rem 0;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-top: 0.125rem solid #39c0ed;
  border-bottom: 0.125rem solid #39c0ed;
  border-left: 0.125rem solid #39c0ed;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-leading,
#chat1 .form-outline .form-control.active ~ .form-notch .form-notch-leading {
  border-right: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-bottom: 0.125rem solid;
  border-color: #39c0ed;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-middle,
#chat1 .form-outline .form-control.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-top: 0.125rem solid #39c0ed;
  border-bottom: 0.125rem solid #39c0ed;
  border-right: 0.125rem solid #39c0ed;
}

#chat1 .form-outline .form-control:focus ~ .form-notch .form-notch-trailing,
#chat1 .form-outline .form-control.active ~ .form-notch .form-notch-trailing {
  border-left: none;
  transition: all 0.2s linear;
}

#chat1 .form-outline .form-control:focus ~ .form-label {
  color: #39c0ed;
}

#chat1 .form-outline .form-control ~ .form-label {
  color: #bfbfbf;
}
.card-header.bg-info {
  background-color: #00aeef !important;
  font-size: 20px;
}
.card-body {
  background-color: #fdfdfd;
  border-radius: 20px;
  height: 80%;
  // max-height: 45px;
  // min-height: 160px;
  overflow-y: auto;
}
.form-outline {
  background-color: #fbfbfb;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-radius: 0px 0px 18px 18px;
  box-shadow: 0px 0px 5px #f2f2f2;
  position: relative;
}
.form-outline input {
  border: transparent;
  padding: 10px;
  background-color: transparent;
  width: 100%;
}
.form-outline input:focus-visible {
  outline: 0px;
}
.form-outline svg {
  width: 20px;
  height: 20px;
  fill: #00aeef;
  position: absolute;
  right: 20px;
  top: 25px;
  cursor: pointer;
}
.card-body-inner img {
  width: 45px;
  height: 100%;
}

.chat-btn {
  position: fixed;
  bottom: 40px;
  right: 60px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.chatbot {
  position: fixed;
  right: 50px;
  bottom: 70px;
  // min-height: 160px;
  z-index: 99;
}
.chatbot-inner {
  // width: 550px;
  width: 100%;
  height: 100%;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.color {
  color: #ffffff;
}

// .text-start > * {
//   font-size: 16px;
// }
