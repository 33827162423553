@import "../../scss/variables.scss";

.clause-outer {
  h4 {
    // cursor: pointer;
    font-size: 32px;
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 11px;
  }
  h5 {
    font-size: 24px;
    font-weight: 500;
    margin: 28px 0px 18px;
  }
  .inter-outer {
    margin-left: 25px;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #828282;
      display: flex;
      span {
        margin-right: 14px;
      }
    }
    hr {
      margin: 28px 0px 18px;
      border-top: 1px solid #e0e0e0;
    }
  }
}
.accordion-header {
  p {
    margin-bottom: 0 !important;
    line-height: 22px;
  }
}
.pdf-inner {
  cursor: pointer;
  margin: 0;
  b {
    font-size: 20px;
    font-weight: $fw-600;
    color: #1d1d1d;
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 24px;
  }
}

.card_box {
}

.wrapBox {
  background-color: #80808012;
  // height: 240px;
  overflow: hidden;
}
.accordion-button:focus {
  border: 0 !important;
  box-shadow: 0 !important;
}
.accordion-button {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
}
.openAccordion {
  background-color: #00aeef;
  padding: 8px 16px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  margin-top: 16px;
}

.expandParagraph {
  .title {
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
      color: #0094cc;
    }
  }
  padding-bottom: 20px !important;
  height: 250px;
  transition: height 0.3s ease;
  .showBtn {
    cursor: pointer;
    inset: auto 0 0 auto;
    padding: 9px 7px 0px 18px;
    background-color: #00aeef;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    left: 0;
    width: 110px;
    display: flex;
    outline: none;
  }
  .showBtn:hover {
    background-color: white;
    color: #00aeef;
    border: 1px solid #00aeef;
  }
}

.show-btnmain {
  // background: #fff;
  background: linear-gradient(0deg, #d0cccc -124%, rgb(255 255 255) 20%);
  width: 100%;
  display: FLEX;
  position: ABSOLUTE;
  background-color: rgba(128, 128, 128, 0.0705882353);
  bottom: 0px;
  height: 65px;
  padding-top: 15px;
}
.shadowTop {
  box-shadow: -1px 1px 38px 44px rgba(255, 255, 255, 1);
}
.transitionHeight {
  transition: all 0.3s ease;
}

.accordion-button:not(.collapsed) {
  color: #000000;
}
