/* CustomSpinner.css */

.custom-spinner-container {
    position: fixed; /* Fix the position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;

    /* Set the height of the container to the full viewport height */
  }

.custom-spinner {
    border: 8px solid #00AEEF; /* Set the border color */
    border-top: 8px solid transparent; /* Make the top transparent to create a spinner effect */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Add a spin animation */

  }

  /* Spinner.css */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.dot {
  display: inline-block;
  margin-right: 10px; /* Adjust spacing between dots as needed */
  animation: move 2s linear infinite, glow 1s ease-in-out infinite alternate;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  animation-delay: 0.6s;
}

.dot:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes move {
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

@keyframes glow {
  0% { text-shadow: 0 0 5px #3498db; color: #3498db; }
  100% { text-shadow: 0 0 20px #3498db; color: #3498db; }
}

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  