.add-clause {
  margin: 60px 0px;

  padding: 0px 20px;
  @media only screen and (max-width: 1600px) {
    height: 80vh;
    overflow-y: auto;
  }
  .custom-checkbox {
    margin: 20px 0px;
  }
  h3 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 30px;
  }

  .custom.textarea {
    display: flex;
    align-items: center;

    textarea {
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 5px;
      width: 100%;
    }
  }

  .heading-outer {
    display: flex;
    flex-direction: column;
    h5 {
      margin: 10px 0px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .custom-checkbox {
    input {
      margin-right: 10px;
    }
    label {
      margin-right: 20px;
    }
  }

  .defe-input {
    display: flex;
    width: 100%;
    align-items: center;

    svg {
      margin-left: 20px;
      cursor: pointer;
    }

    p {
      margin: 0px;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 400;
      width: 80px;
    }

    input {
      border: 1px solid #ccc;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
    }

    textarea {
      border: 1px solid #ccc;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
    }
  }

  .custom-definition {
    margin-top: 30px;
  }

  .add-user {
    margin: 20px 0px;
    text-align: right;
  }
}

.submit_btn {
  background-color: #00aeef;
  border: 1px solid #00aeef;
  width: 106px;
  color: white;
  padding: 5px;
  border-radius: 20px;
  margin-bottom: 2rem;
}
.submit_btn:hover {
  background-color: #ffffff;
  // border-color: #00aeef;
  border: 1px solid #00aeef;
  width: 106px;
  color: #00aeef;
  padding: 5px;
  border-radius: 20px;
}

.defe-input-btn {
  text-align: right;
  margin-top: 20px;
  svg {
    width: 30px;
    cursor: pointer;
  }
}

.back {
  height: 20px;
  left: 300px;
  top: 50px;
}
.date {
  border-radius: 4px;
  padding: 0 3px;
  border: 2px solid grey;
}
.w-65 {
  width: 65%;
}
.position {
  max-height: max-content;
  max-width: max-content;
}
