@import "../../scss/variables.scss";

.contrast-list {
  position: relative;
  .custom-drop {
    position: absolute;
    right: 0;
    top: 0.45rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid grey;
    height: 36px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
    .dropdown {
      display: inline-block;
      margin-left: 1.25rem;
      color: $black2;
    }
    .dropdown-toggle {
      &::after {
        margin-left: 1.625rem !important;
      }
    }
    .nav-link {
      font-size: 16px;
      font-weight: $fw-500;
      color: $black2;
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }
  .common-search {
    margin: 0 auto;
    text-align: center;
  }
}
.data-table {
  .isdzTY {
    border-top-style: none;
  }
  .rdt_TableCol {
    font-size: 1.5rem;
    font-weight: $fw-500;
    color: $black2;
  }
  .rdt_TableHeadRow {
    border-bottom-style: dashed;
  }
  .rdt_TableCell {
    font-size: 16px;
    font-weight: $fw-400;
    color: $grey2;
    @media screen and (max-width: 1366px) {
      font-size: 12px;
    }
  }
  .rdt_TableRow {
    background-color: $lightblue;
    border-bottom: 0.0625rem solid $grey4;
    border-style: dashed;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    min-height: 3.125rem;
    cursor: pointer;
  }

  .bPokRt:not(:last-of-type) {
    border-bottom-style: dashed;
    border-bottom-width: 0.0625rem;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  .rdt_TableCell {
    &:last-child {
      justify-content: start;
    }
  }
}
.data-table {
  .dahnUA {
    div {
      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }
}

#drop-area {
  position: relative;
}

#fileElem {
  display: none;
}

.upload-label {
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
}
.upload-label:hover {
  background-color: #e9e9e9;
}

.upload-label:active {
  background-color: #f6f6f6;
}

.upload-text {
  color: #666;
  -webkit-user-select: none;
}

.imageContainer {
  min-width: 100%;
  max-width: 100%;
  height: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.pdf {
  width: 100%;
  height: 159px;
  overflow-y: hidden;
}
.chatBox {
  width: 100%;
  height: 13rem;
  .box {
    width: 100%;
    height: 80%;
    border: 1px solid black;
    margin-bottom: 2px;
    .userChat {
      text-align: end;
      padding-right: 3px;
      width: 45%;
      margin-left: 54%;
      border: 1px solid grey;
    }
    .botChat {
      text-align: start;
      padding-left: 3px;
      width: 45%;
      margin-right: 54%;
      border: 1px solid grey;
    }
  }
  .botm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid grey;
    .ip {
      width: 90%;
      border: transparent;
    }
    .send {
      border: none;
      width: 10%;
    }
    .send:active {
      border: none;
      width: 10%;
      background-color: white;
    }
  }
}

.sc-dQpIV.ofEne {
  font-size: 20px;
  font-weight: 600;
}

.add_role {
  position: absolute;
  top: 0px;
  right: 0px;
}
.input_head {
  border: 0;
  border-bottom: 1px solid grey;
}

