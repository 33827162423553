@import "../../scss/variables.scss";

header {
  height: 5rem;
  box-shadow: 0rem 0.25rem 1.25rem 0.3125rem rgba(0, 0, 0, 0.05);
  padding: 0rem 0.9375rem;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $white;
  @media only screen and (max-width: 1366px) {
    height: 3.125rem;
    .navbar-expand-lg {
      height: 3.125rem;
    }
  }
  @media only screen and (max-width: 992px) {
    .navbar-expand-lg {
      height: 3.125rem;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}

.navbar-nav {
  align-items: center;
  .dropdown-toggle {
    font-size: 1.125rem;
    font-weight: $fw-500;
    color: $black2;
    @media only screen and (max-width: 1366px) {
      font-size: 0.875rem;
      padding-left: 0rem !important;
      padding: 0rem !important;
    }
    @media only screen and (max-width: 1200px) {
      padding: 0rem 8px 0rem 0rem;
      margin-top: -0.4375rem;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    min-width: unset;
    border: transparent;
    background-color: transparent;
    padding: 0rem;
    top: 1.875rem;
    .dropdown-item {
      font-size: 0.875rem;
      font-weight: $fw-400;
      color: $grey4;
      padding: 0px 0.5rem;
      margin-top: -0.625rem;
    }
  }
  .nav-link {
    img {
      width: 2.3125rem;
      height: 2.3125rem;
      border-radius: 3.125rem;
      @media only screen and (max-width: 1366px) {
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }
}
.navbar-brand {
  img {
    width: 10.625rem;
    margin-top: -0.625rem;
    @media only screen and (max-width: 1366px) {
      width: 9.375rem;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .search-outer {
    margin: 1.25rem auto 1.25rem !important;
  }
}
.dropdown-item {
  &:active {
    color: $primary !important;
    text-decoration: none;
    background-color: transparent !important;
  }
}
