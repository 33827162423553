@import "../../scss/variables.scss";

.account-outer {
  position: relative;
  height: 100%;
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    border: 0.0625rem solid $grey5;
    left: 50%;
    top: 3.0625rem;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .account-topheading {
    h3 {
      margin: 3.4375rem 0rem 0rem;
      font-size: 1.5rem;
      font-weight: $fw-500;
      color: $black2;
      border-bottom: 0.0625rem solid $grey5;
      padding-bottom: 1.25rem;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .profile-details {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0rem;
    align-items: center;

    h4 {
      font-size: 1.25rem;
      font-weight: $fw-400;
    }
  }
  .upload-inner {
    position: relative;
    margin-left: 8.75rem;

    @media only screen and (max-width: 992px) {
      margin-left: 0rem;
    }

    .edit-photo {
      position: absolute;
      left: 3.75rem;
      top: 0rem;
      // z-index: 999;
      cursor: pointer;
    }
    input {
      position: absolute;
      top: 0;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      font-size: 0;
      border: transparent !important;
      background-color: transparent !important;
      opacity: 0;
    }
    img {
      width: 5.25rem;
      height: 5.25rem;
      border-radius: 3.125rem;
      border: 0.0625rem solid $grey5;
    }
  }
  .profile-inner {
    h5 {
      font-size: 16px;
      font-weight: $fw-400;
      color: $black2;
    }
  }
}
.profile-inner {
  position: relative;
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }

  h4 {
    position: absolute;
    right: 6.25rem;
    font-size: 16px;
    font-weight: $fw-400;
    @media only screen and (max-width: 992px) {
      margin-top: 0.625rem;
      left: 0rem;
      right: 0rem;
    }
    a {
      color: $grey4;
    }
  }
  .form-inner {
    margin-top: 2.5rem;
    .label-outer {
      margin-bottom: 0.8125rem;
      display: flex;
      align-items: center;
      label {
        font-size: 1.125rem;
        font-weight: $fw-400;
        color: $black2;
        margin-left: 0.4375rem;
      }
    }
  }
  .save-btn {
    margin-top: 2.5rem;
    .disabled {
      background-color: $grey5;
    }
  }
}
.adminBtn {
  color: var(--black-colors-black-2, #1d1d1d);
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.logoutBtn {
  color: var(--grey-colors-gray-4, #bdbdbd);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.custom_heading {
  border-bottom: 2px solid $grey5;
}
