@import "../../scss/variables.scss";

.clause-details {
  .hub-outer {
    position: absolute;
    top: -10px;
    left: -10px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .back {
    span {
      font-size: 16px;
      font-weight: $fw-400;
      margin-left: 0.9375rem;
      display: inline-block;
      color: $grey3;
    }
  }
  .terms-outer {
    .terms-innertext {
      border: 0.0625rem solid $grey5;
      padding: 1.125rem;
      border-radius: 0.3125rem;
      margin-bottom: 1.0625rem;
      max-height: 700px;
      overflow-y: scroll;
      p {
        font-size: 16px;
        font-weight: 400;
        color: $grey3;
        b {
          font-weight: $fw-700;
        }
      }
      h4 {
        font-size: 16px;
        font-weight: $fw-400;
        color: $black2;
        cursor: pointer;
      }
      hr {
        margin: 0.75rem 0rem !important;
        color: $black2;
      }
    }
    h5 {
      font-size: 1.125rem;
      font-weight: $fw-500;
      color: $black1;
      margin-bottom: 0.8125rem;
    }
    input {
      margin-bottom: 1.625rem;
    }
  }
  .righ {
    max-height: 700px;
  }
  hr {
    color: $grey5;
    margin: 0px 0px 30px;
  }
}
.common-search {
  margin: 0 auto;
  text-align: center;
}
.origin-outer {
  h3 {
    font-size: 1.125rem;
    font-weight: $fw-500;
    color: $black2;
    border-bottom: 0.0625rem solid $grey5;
    padding-bottom: 0.5625rem;
  }
  p {
    font-size: 16px;
    font-weight: $fw-400;
    color: $grey3;
  }
}
.version-outer {
  .main-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.0625rem solid $grey5;
  }
  h3 {
    border-bottom: 0.0625rem solid transparent;
    margin-bottom: 0rem;
  }
  .copy-outer {
    svg {
      margin-left: 1.25rem;
      cursor: pointer;
    }
  }
}
.clause-details {
  margin-bottom: 2.5rem;

  .history-outer {
    p {
      margin-bottom: 0.875rem;
      span {
        font-size: 1rem;
        color: $primary;
        font-weight: $fw-400;
        margin-left: 0.25rem;
      }
    }
    h4 {
      margin: 0.875rem 0rem;
      font-weight: $fw-500 !important;
      font-size: 16px;
      color: $black2;
    }
    h5 {
      font-size: 16px !important;
      font-weight: $fw-400 !important;
      color: $grey3 !important;
      display: flex;
      line-height: 1.5625rem;
      b {
        margin-right: 0.3125rem;
        display: inline-block;
        font-weight: $fw-600;
      }
    }
    .inner-history {
      margin-top: 1.5rem;
    }
  }
}
.terms-link {
  h4 {
    color: $primary !important;
  }
}
.origin-outer {
  margin-top: 50px;
}

.link {
  color: #00aeef;
}

.pointer {
  cursor: pointer;
}
.search-outer {
  .breadCrumb {
    color: #212121;
    font-weight: 600;
  }
}
.blue_text {
  color: rgb(0, 174, 239);
}
.flex_text {
  display: flex;
  text-align: center;
  align-items: baseline;
}
.flex_text_content {
  display: flex;
  text-align: start;
  align-items: baseline;
}
.ABC021212 {
  color: #828282 !important;
}
.main-history {
  p {
    margin: 0 !important;
    line-height: 22px !important;
  }
}
