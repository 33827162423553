
//Brand Colors

$primary:#00AEEF;
$secondary:#2D434B;;

//State Colors
$info:#2F80ED;
$sucess:#27AE60;
$warning:#E2B93B;
$error:#EB5757;
$white:#fff; 
$lightblue: #f9fdff;

// Black Colors
$black1:#000;
$black2:#1D1D1D;
$black3:#282828;


//Grey Colors
$grey1:#333333;
$grey2:#4F4F4F;
$grey3:#828282;
$grey4:#BDBDBD;
$grey5:#E0E0E0;



//Font Weight

$fw-300:300;
$fw-400:400;
$fw-500:500;
$fw-600:600;
$fw-700:700;
$fw-800:800;

//Font Size

$heading1:56px;
$heading2:48px;
$heading3:40px;
$heading4:32px;
$heading5:24px;
$fs-text:20px;


$mdtext:18px;
$normaltext:16px;
$smtext:14px;