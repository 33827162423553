@import "../../scss/variables.scss";

.dashboard-main {
  .search-outer {
    position: relative;
    margin: 3.5625rem auto 3rem;
    input {
      border: transparent;
      border-bottom: 0.0625rem solid $grey5;
      padding: 0.6875rem 2.5rem;
      width: 50%;
      height: 43px;
      @media only screen and (max-width: 1366px) {
        padding: 0.5rem 1.5625rem;
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .left-icon {
      position: absolute;
      top: 0.5625rem;
      left: 25%;
      cursor: pointer;
      @media only screen and (max-width: 992px) {
        left: 0rem;
      }
    }
    .right-icon {
      position: absolute;
      top: 0.625rem;
      right: 25%;
      cursor: pointer;
      @media only screen and (max-width: 992px) {
        right: 0rem;
      }
    }
  }
  .link-history {
    text-align: left;
    .scroll-outer {
      max-height: 520px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .histor-inner {
      border-bottom: 0.0625rem solid $grey5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.625rem;
      padding-bottom: 10px;
      h4 {
        font-size: 1.25rem;
        font-weight: $fw-600;
        color: $black2;
        @media only screen and (max-width: 1366px) {
          font-size: 18px;
        }
      }
    }
  }
  .box-inner {
    display: flex;
    border-bottom: 0.0625rem solid $grey5;
    border-style: dashed;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    @media only screen and (max-width: 992px) {
      justify-content: space-between;
    }
  }
  .text-contract {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1d1d1d;
  }
  .small-logo {
    margin-right: 0.8125rem;
  }
  .admin-text {
    h4 {
      font-size: 16px;
      font-weight: $fw-400;
      color: $black2;
      margin-bottom: 0.625rem;
      text-align: left;
      @media only screen and (max-width: 1366px) {
        font-size: 0.875rem;
      }
    }
    p {
      font-size: 0.875rem;
      color: $black3;
      font-weight: $fw-400;
      margin-bottom: 0rem;
      text-align: left;
      @media only screen and (max-width: 1366px) {
        font-size: 0.75rem;
      }
    }
  }
  .delete-box {
    margin-left: 0;
    margin-right: 0.625rem;
    width: 6.25rem;
    text-align: right;
    svg {
      margin-top: 1.25rem;
      cursor: pointer;
    }
    p {
      font-size: 0.875rem;
      color: $black3;
      font-weight: $fw-400;
      margin-bottom: 16px;
    }
    .delete-icon {
      text-align: right;
    }
  }
  .single-box {
    margin: 0 auto;
    text-align: center;

    h5 {
      position: relative;
      font-size: 0.875rem;
      font-weight: $fw-400;
      color: $primary;
      // margin-top: 0.625rem;

      span {
        background-color: $white;
        display: inline-block;
        padding: 0rem 0.625rem;
      }
      &::before {
        position: absolute;
        content: "";
        border-bottom: 0.0625rem solid $grey4;
        width: 100%;
        border-style: dashed;
        top: 0.5625rem;
        border-top: transparent;
        left: 0rem;
        z-index: -1;
      }
    }
  }
  .without-line {
    margin-top: 0.9375rem;
  }
  .no-border {
    border-bottom: transparent;
  }
  .sharred-isting {
    margin-top: 0;
    border-bottom: 0.0625rem solid $grey4;
    border-style: dashed;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    cursor: pointer;

    .single-list {
      background-color: $lightblue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.9375rem;
      position: relative;
      @media only screen and (max-width: 1366px) {
        padding: 0.625rem;
      }
      &:hover {
        background-color: $white;
      }
      p {
        color: $grey2;
        font-size: 16px;
        font-weight: $fw-400;
        margin-bottom: 0rem;
        @media only screen and (max-width: 1366px) {
          font-size: 12px;
        }
      }
    }
  }
  .contract-hub {
    .histor-inner {
      margin-bottom: 0.625rem;
      padding-bottom: 0.625rem;
      border-bottom: transparent;
      @media only screen and (max-width: 992px) {
        margin-top: 1.25rem;
      }
    }
  }
  .note-reminder {
    .histor-inner {
      border-bottom: transparent !important;
      margin-top: 1.0625rem;
      padding-bottom: 0rem;
      margin-bottom: 0.75rem;
      display: inherit;

      h4 {
        margin-bottom: 0rem;
      }
    }
    .custom-textarea {
      margin-bottom: 16px;
    }
  }
  .btm-btn {
    display: inherit !important;
    border-bottom: transparent !important;
    button {
      width: 100%;
    }
  }
  .single-list {
    p {
      &:first-child {
        width: 25rem;
        @media only screen and (max-width: 1366px) {
          width: 216px;
        }
      }
    }
  }
  .main-listing {
    max-height: 20.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sharred-isting {
    &:hover {
      .single-list {
        &:before {
          position: absolute;
          content: "";
          height: 1.5625rem;
          width: 0.0625rem;
          border: 0.0625rem solid $primary;
          left: 0;
        }
      }
    }
  }
  .sharred-isting {
    &:hover {
      .single-list {
        &:after {
          position: absolute;
          content: "";
          height: 1.5625rem;
          width: 0.0625rem;
          border: 0.0625rem solid $primary;
          right: 0;
        }
      }
    }
  }
  .common-search.search-outer {
    svg {
      @media only screen and (max-width: 1366px) {
        width: 0.875rem;
      }
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: $grey5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey4;
}
.mt-none {
  p {
    margin-bottom: 0.3125rem !important;
  }
  .delete-box {
    svg {
      margin-top: 1.25rem;
      margin-bottom: 0.625rem;
    }
  }
}
.dashboard-search {
  margin: 0px 0px 20px !important;
  input {
    width: 100% !important;
  }
  .left-icon {
    left: 0rem !important;
  }
  .right-icon {
    right: 0px !important;
  }
}
.dashboard-search.dashboards {
  display: flex;
  align-items: center;
  .histor-inner {
    margin-left: 20px;
  }
}
.single-box {
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #1d1d1d;
  }
  p {
    span {
      font-size: 14px;
      font-weight: 400;
      color: #4f4f4f;
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }
}
.customd {
  input {
    padding: 10px 30px 8px !important;
    margin-top: -10px;
    border-radius: 3px;
  }
}
.delete-modal {
  .modal-body {
    padding: 30px;
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .custom-btn {
    font-size: 15px;
    min-height: 35px !important;
  }
}

// .container {
//   max-width: 1820px !important;
// }
.container {
  max-width: 80% !important;
}
.historySize {
  font-size: 16px !important;
}
.chatHeader {
  b {
    p {
      font-size: 16px !important;
    }
  }
  p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1366px) {
  .historySize {
    font-size: 12px !important;
  }
  .chatHeader {
    b {
      p {
        font-size: 12px !important;
      }
    }
    p {
      font-size: 12px !important;
    }
  }
}

.historyText {
  line-height: normal !important;
}
