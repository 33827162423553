@import "../../scss/variables.scss";

.main-login {
  background-image: url("../../images/login-bg.png");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  @media only screen and (max-width: 768px) {
    background-image: none;
    height: auto;
  }
  .login-inner {
    width: 60%;
    margin: 0 auto;

    height: 100%;
    text-align: left;
    background-color: $white;
    display: flex;
    max-height: 44.375rem;
    @media only screen and (max-width: 1366px) {
      max-height: 34.375rem;
    }
    @media only screen and (max-width: 1200px) {
      width: 70%;
    }
    @media only screen and (max-width: 992px) {
      width: 90%;
      display: inherit;
    }
    .text-inner {
      padding: 0 1.875rem;
      @media only screen and (max-width: 768px) {
        padding: 0rem;
      }
      h1 {
        font-size: 3rem;
        color: $primary;
        display: inline-block;
        font-weight: $fw-700;

        @media only screen and (max-width: 1366px) {
          font-size: 2rem;
          // margin-left: 16px;
        }
        @media only screen and (max-width: 992px) {
          margin-left: 0rem;
        }
        @media only screen and (max-width: 992px) {
          font-size: 2rem;
        }
      }
      h3 {
        font-size: 3rem;
        font-weight: $fw-400;
        display: inline-block;
        margin-left: 0.625rem;
        color: $primary;
        margin-bottom: 0px;
        @media only screen and (max-width: 1400px) {
          font-size: 2rem;
          margin-left: 5px;
        }
        @media only screen and (max-width: 992px) {
          font-size: 1.25rem;
          margin-left: 0.9375rem;
        }
      }
      h2 {
        font-size: $heading2;
        text-align: start;
        color: $primary;
        margin: 0 0 0.625rem 0;
        @media only screen and (max-width: 1366px) {
          font-size: 2.375rem;
        }
        @media only screen and (max-width: 992px) {
          font-size: 1.5rem;
        }
        @media only screen and (max-width: 768px) {
          font-size: 1.125rem;
          margin-bottom: 0.3125rem;
        }
      }
      h4 {
        text-align: start;
        font-size: 1.5rem;
        margin: 0 0rem 1.875rem;
        font-weight: $fw-400;

        @media only screen and (max-width: 1400px) {
          font-size: 1.25rem;
        }
        @media only screen and (max-width: 992px) {
          margin: 0rem;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          margin-top: 0.625rem;
        }
      }
    }
    .logo-outer {
      position: relative;
      display: grid;
      justify-content: left;
      width: 50%;
      align-items: center;
      @media only screen and (max-width: 768px) {
        width: 100%;
        display: inherit;
      }

      @media only screen and (max-width: 992px) {
        .text-inner {
          text-align: left;
          width: 100%;
          margin: 3.5rem 0rem 1.5625rem;
        }
      }

      .logo {
        position: absolute;
        left: 1.875rem;
        top: 1.875rem;
        @media only screen and (max-width: 992px) {
          top: 1.25rem;
        }
        @media only screen and (max-width: 768px) {
          left: 0rem;
        }
        img {
          @media only screen and (max-width: 1366px) {
            width: 9.375rem;
          }
        }
      }
    }
  }

  .welcome-outer {
    padding: 4.375rem 2.75rem;
    opacity: 0.9;
    background: $secondary;
    width: 50%;
    @media only screen and (max-width: 1366px) {
      padding: 1.875rem;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      padding: 0.9375rem;
    }

    h3 {
      font-size: $heading3;
      text-align: start;
      color: $white;
      font-weight: $fw-400;
      @media only screen and (max-width: 1400px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 1.625rem;
      }
    }
    .subtitle {
      margin-top: 1.5625rem;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1366px) {
        margin-top: 0.625rem;
      }
      @media only screen and (max-width: 1366px) {
        svg {
          width: 1.875rem;
          height: 1.875rem;
        }
      }

      h6 {
        font-size: $fs-text;
        color: $white;
        font-weight: $fw-400;
        margin-left: 0.75rem;
        @media only screen and (max-width: 1400px) {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }

    .backtologin {
      color: #bdbdbd;
      font-size: 16px;
      font-weight: 400;
      // font-size: $fs-text;
      //   color: $white;
      //   font-weight: $fw-400;
      cursor: pointer;
    }

    .input-outer {
      .text-danger {
        margin-top: 0.625rem;
        margin-bottom: 0;
      }
      margin-top: 1.875rem;
      @media only screen and (max-width: 1366px) {
        margin-top: 1.25rem;
      }
      label {
        font-size: $fs-text;
        font-weight: $fw-400;
        margin-bottom: 1.0625rem;
        color: $white;
        display: block;
        @media only screen and (max-width: 1366px) {
          margin-bottom: 0.625rem;
          font-size: 16px;
        }
        span {
          color: $error;
        }
      }
      input {
        width: 100%;
        padding: 0rem 1.25rem 0rem 3.25rem;
        border-radius: 0.625rem;
        border: transparent;
        font-size: 1.125rem;
        color: $grey4;
        background-color: $white;
        height: 3.125rem;
        @media only screen and (max-width: 1366px) {
          padding: 0rem 1.25rem 0rem 2.1875rem;
        }
      }
      .input-inner {
        position: relative;
        svg {
          position: absolute;
          top: 0.9375rem;
          left: 1.25rem;
          @media only screen and (max-width: 1366px) {
            top: 16px;
            left: 0.625rem;
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }
    .remeber-inner {
      margin: 1.5625rem 0rem 3.125rem;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 1367px) {
        margin: 1.25rem 0rem 0px;
      }
      @media only screen and (max-width: 768px) {
        margin: 1.25rem 0rem 1.25rem;
      }
      input {
        width: 1.125rem;
        height: 1.125rem;
      }
      label {
        color: $grey4;
        font-size: 16px;
        font-weight: $fw-400;
      }
      a {
        color: $grey4;
        font-size: 16px;
        font-weight: $fw-400;
      }
    }
    .submit-btn {
      button {
        height: 3.75rem;
        width: 100%;
        font-size: 1.25rem !important;
        font-weight: $fw-400 !important;
        background-color: $primary;
        border: 0.0625rem solid transparent;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        color: $white !important;
        border-radius: 0.3125rem;
        border: 0.0625rem solid transparent !important;
        @media only screen and (max-width: 1366px) {
          min-height: 2.8125rem;
          font-size: 16px;
        }
        &:hover {
          background-color: transparent !important;
          border: 0.0625rem solid $white !important;
        }
      }
    }
  }
}
.forgot-outer {
  .login-inner {
    max-height: 32rem;
  }
}
.peofile-details {
  box-shadow: 0rem 0rem 0.3125rem $grey4;
  border-radius: 0.625rem;
  padding: 3.125rem;
  margin: 3.125rem;
  h3 {
    text-align: center;
    margin-bottom: 1.875rem;
  }
  p {
    margin-bottom: 1.25rem;
  }
}
.forgot-icon {
  h6 {
    margin-bottom: 0;
  }
}
